import React from "react";
// src/pages/past-order/[id].js

// id from the url, generates a url of `past-order/:id`, which when a user visits
// the field will be passed to the component here.
export default function CakeTemplate ({ params }) {
  // if the user visits `/past-order/123`, then id here is `123`
  const id = params.code
  return (<>
    {id}
  </>)
}
